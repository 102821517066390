<template>
	<WwModal
		:modal-id="REPORT_REVIEW_MODAL"
		title="Report an Issue"
		:header-offset="235"
		ok-title="Send Report"
		ok-color="red"
		ok-text-color="text-white"
		:ok-disabled="!reportIsValid"
		@ok="handleSubmit"
	>
		<template #body>
			<div class="flex flex-col w-full mb-4">
				<label for="report-reason">
					Reason for reporting
				</label>
				<select
					v-model="report.reason"
					name="report-reason"
					class="p-1 placeholder-gray-500 bg-gray-200 border border-gray-300 rounded focus:ring focus:ring-green-300"
				>
					<option
						disabled
						value=""
					>
						Choose a reason
					</option>
					<option
						v-for="reason in reasons"
						:key="reason.value"
						:value="reason.value"
					>
						{{ reason.text }}
					</option>
				</select>
			</div>
			<div class="flex flex-col w-full">
				<label for="report-content">
					Tell us more
				</label>
				<textarea
					v-model="report.report"
					rows="3"
					name="report-content"
					:placeholder="placeholderText"
					class="w-full p-2 mb-4 placeholder-gray-500 bg-gray-200 border-none rounded focus:ring focus:ring-green-300"
				/>
				<div class="text-sm italic">
					Submitted information is kept private
				</div>
			</div>
		</template>
	</Wwmodal>
</template>

<script async>
import { mapMutations } from 'vuex'

import WwModal from '@/components/UI/WwModal.vue'
import { REPORT_REVIEW_MODAL } from '@/constants/modal/names.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import { ReportListingReview } from '@/gql/business/mutations.gql'

export default {
	components: {
		WwModal
	},
	props: {
		reviewToReport: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			reasons: [
				{ value: 'vindictive_comments', text: 'Vindictive Comments' },
				{ value: 'wrong_business', text: 'Wrong Business' },
				{ value: 'vulgar_language', text: 'Vulgar Language' },
				{ value: 'biased_party', text: 'Biased Party' },
				{ value: 'other', text: 'Other' }
			],
			report: {
				reason: '',
				report: ''
			},
			REPORT_REVIEW_MODAL
		}
	},
	computed: {
		reportIsValid() {
			return !!this.report?.reason.length &&
				!!this.report?.report.length
		},
		placeholderText() {
			return 'Tell us what\'s wrong with this review and we\'ll remove it or take appropriate actions as soon as we can verify the information.'
		}
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		...mapMutations('modal', [ 'closeModal' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeModal()
		},
		handleSubmit() {
			this.$apollo.mutate({
				mutation: ReportListingReview,
				variables: {
					reviewId: this.reviewToReport.review_id,
					listingId: this.reviewToReport.listing_id,
					reason: this.report.reason,
					message: this.report.report
				}
			}).then(() => {
				this.showToast({
					primaryText: 'Success',
					secondaryText: 'Review reported!',
					type: SUCCESS
				})
			}).catch(() => {
				this.showToast({
					primaryText: 'Invalid',
					secondaryText: 'There was a problem. Please try again.',
					type: ERROR
				})
			})
			this.closeModal()
		}
	}
}
</script>
